<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols='12'>
          <v-card-title >
            6. Первая помощь при ранениях
          </v-card-title>
        </v-col>
        <v-col
          cols="12"
          class="pb-0"
        >
            <v-alert
              border="left"
              colored-border
              color="red"
              class="title font-weight-light pl-10"
              elevation="2"
            >
            <strong>Ранение </strong> - нарушение целостности кожи, слизистой оболочки, органов в результате внешнего механического воздействия.
          </v-alert>
        </v-col>
        <v-col cols="6">
          <v-card-title class="mb-10">
            ЗАПРЕЩЕНО:
          </v-card-title>
          <div class="ptm-img-rel justify-center align-center mt-16">
            <v-img
              lazy-src="img/material/two-man.svg"
              width="60%"
              contain
              src="img/material/two-man.svg"
              class="d-flex ptm-img"
            ></v-img>
            <v-tooltip right color="red">
              <template v-slot:activator="{ on }">
                <v-btn dark v-on="on" rounded class="ptm-img-man-2-item-1 title pa-5 red"><v-icon>mdi-information-outline</v-icon></v-btn>
              </template>
              <span class="pa-5 pl-0 subtitle-1">Прикасаться к ране незащищенными руками</span>
            </v-tooltip>
            <v-tooltip right color="red">
              <template v-slot:activator="{ on }">
                <v-btn dark v-on="on" rounded class="ptm-img-man-2-item-2 title pa-5 red"><v-icon>mdi-information-outline</v-icon></v-btn>
              </template>
              <span class="pa-5 pl-0 subtitle-1">При наложении повязки прикасаться<br> к стороне бинта, прилежащей к ране</span>
            </v-tooltip>
            <v-tooltip right color="red">
              <template v-slot:activator="{ on }">
                <v-btn dark v-on="on" rounded class="ptm-img-man-2-item-3 title pa-5 red"><v-icon>mdi-information-outline</v-icon></v-btn>
              </template>
              <span class="pa-5 pl-0 subtitle-1">Удалять из раны сгустки крови, инородные </span>
            </v-tooltip>
            <v-tooltip right color="red">
              <template v-slot:activator="{ on }">
                <v-btn dark v-on="on" rounded class="ptm-img-man-2-item-4 title pa-5 red"><v-icon>mdi-information-outline</v-icon></v-btn>
              </template>
              <span class="pa-5 pl-0 subtitle-1">Вдавливать во внутрь раны выступающие наружу<br> ткани или органы – их необходимо прикрыть<br> сверху чистой марлей</span>
            </v-tooltip>
            <v-tooltip right color="red">
              <template v-slot:activator="{ on }">
                <v-btn dark v-on="on" rounded class="ptm-img-man-2-item-5 title pa-5 red"><v-icon>mdi-information-outline</v-icon></v-btn>
              </template>
              <span class="pa-5 pl-0 subtitle-1">Промывать рану водой или лекарственными средствами,<br> заливать йодом или спиртом, засыпать порошком,<br> покрывать мазями, накладывать вату непосредственно на рану</span>
            </v-tooltip>
          </div>
            <v-sheet flat class="d-flex justify-center mx-auto pb-0 mt-2">
              <v-card class="d-flex justify-center" flat>
                <v-icon color="red" size="30">mdi-cursor-pointer</v-icon>
                <v-card-title class="font-weight-light">
                  Наводите на кнопки для изучения информации
                </v-card-title>
              </v-card>
            </v-sheet>
        </v-col>
        <v-col cols="6">
          <v-card-title class="mb-10">
            Действия:
          </v-card-title>
          <v-card
          class="pa-1 d-flex justify-center align-center"
          full-width
          flat

          >
            <v-icon color="red" size="100">mdi-numeric-1</v-icon>
            <v-card-text class="title font-weight-light">
              Остановить кровотечение;
            </v-card-text>
          </v-card>
          <v-card
          class="pa-1 d-flex justify-center align-center"
          full-width
          flat

          >
            <v-icon color="red" size="100">mdi-numeric-2</v-icon>
            <v-card-text class="title font-weight-light">
              Обработать края раны доступным дезинфицирующим средством;
            </v-card-text>
          </v-card>
          <v-card
          class="pa-1 d-flex justify-center align-center"
          full-width
          flat

          >
            <v-icon color="red" size="100">mdi-numeric-3</v-icon>
            <v-card-text class="title font-weight-light">
              Наложить стерильную повязку. Нетуго перебинтовать;
            </v-card-text>
          </v-card>
          <v-card
          class="pa-1 d-flex justify-center align-center"
          full-width
          flat

          >
            <v-icon color="red" size="100">mdi-numeric-4</v-icon>
            <v-card-text class="title font-weight-light">
              Зафиксировать раненную конечность (на косынке или с помощью шин);
            </v-card-text>
          </v-card>
          <v-card
          class="pa-1 d-flex justify-center align-center"
          full-width
          flat

          >
            <v-icon color="red" size="100">mdi-numeric-5</v-icon>
            <v-card-text class="title font-weight-light">
              Транспортировать в медицинское учреждение.
            </v-card-text>
          </v-card>
          <v-dialog
            v-model="dialog1"
            width="80%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="red"
                  class="mt-10"
                  v-bind="attrs"
                  v-on="on"
                  tag="a"
                  dark
                  outlined
                  height="70"
                  >
                <v-card-text class="title font-weight-light">
                  Способы транспортирования пострадавшего
                </v-card-text>
              </v-btn>
            </template>
            <v-card>
              <Modal1></Modal1>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog1 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <Hint></Hint>
    </v-container>
  </div>
</template>
<script>
import Modal1 from '@/components/Page49/Modal1.vue'
import Hint from '@/components/Hint.vue'
export default {
  data: () => ({
    dialog1: null
  }),
  components: {
    Modal1,
    Hint
  }
}
</script>
<style lang="less" scoped>
.ptm-img-rel {
  position: relative;
}
.ptm-img-man-2-item-1 {
  position: absolute;
  top: 0%;
  right: 15%;
}
.ptm-img-man-2-item-2 {
  position: absolute;
  top: 20%;
  right: 15%;
}
.ptm-img-man-2-item-3 {
  position: absolute;
  top: 40%;
  right: 15%;
}
.ptm-img-man-2-item-4 {
  position: absolute;
  top: 60%;
  right: 15%;
}
.ptm-img-man-2-item-5 {
  position: absolute;
  top: 80%;
  right: 15%;
}
</style>
